import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MemoriesService {

  constructor(private http:HttpClient) { }
  baseUrl = environment.url;
  access_key: string = localStorage.getItem("accessToken");
  headers: any = new HttpHeaders()
    .set("Authorization", `${this.access_key}`)
    .set("Content-Type", "application/json");
  requestOptions: any = { headers: this.headers };
  apiUrl: any;
  url:any;

  // getMemories(){
  //   this.apiUrl = `${this.baseUrl}/appraisal/api/memories/`
  //    this.url = this.apiUrl;
  //   return this.http.get(this.url, this.requestOptions);
  // }

  getMemoriesCategories(category:string){
    this.apiUrl = `${this.baseUrl}appraisal/api/memories/?category=${category}`
    this.url = this.apiUrl;
    return this.http.get(this.url, this.requestOptions);
  }

  getSingleMemories(id:number){
    this.apiUrl = `${this.baseUrl}appraisal/api/memories/${id}/`
    this.url = this.apiUrl;
    return this.http.get(this.url, this.requestOptions);
  }
}
